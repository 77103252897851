














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'

@Component({
  // inheritAttrs: false,
})
export default class BaseDatepicker extends Vue {
  isVisible = false

  @Prop({ default: () => uuidv4() })
  id!: string

  @Prop({ default: 'date' })
  type!: string

  @Prop()
  value!: Date | string | number

  @Prop({ default: '' })
  placeholder!: string

  @Prop({ default: 'DD.MM.YYYY' })
  format!: string

  @Prop()
  label!: string

  @Prop()
  minDate!: string | Date

  @Prop()
  maxDate!: string | Date

  @Prop()
  visibleYearsNumber!: number

  @Prop()
  help!: string

  @Prop()
  errorTex!: string

  @Prop({ default: false })
  hasError!: boolean

  @Prop({ default: false })
  noHeader!: boolean

  @Prop({ default: false })
  fullscreenMobile!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: false })
  range!: boolean

  get initValue(): unknown {
    return this.value
  }

  set initValue(val: unknown) {
    this.$emit('input', val)
  }

  get hasCardSlot(): boolean {
    return !!this.$scopedSlots.icon || !!this.$slots.icon
  }
}
